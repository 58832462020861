/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';

const ViewVariant = React.memo(() => {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const imageUrl = `${envValues.REACT_APP_API_FRONT_END}/upload/product/`;
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const { variantId } = useParams();
  const token = `Bearer ${getCookies('Token')}`;
  const [details, setDetails] = useState({ loading: true, data: [] });
  let statusCode;
  const getCategoryDetails = async () => {
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/product/variant/${variantId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        console.log(res, 'res');
        if (statusCode === 200) {
          setDetails({ loading: false, data: res.data.data.result });
        } else {
          setDetails({ loading: false });
        }
      })
      .catch((err) => {
        setDetails({ loading: false });
        return err;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategoryDetails();
    setDashboardHeader('Variant Details');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {details.loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#39979d',
              }}
            />
          ) : (
            <Container fluid>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Color</span>
                          <p className={styles.empname}>{details.data.color}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Reorder</span>
                          <p className={styles.empname}>{details.data.reorder}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Price</span>
                          <p className={styles.empname}>{details.data.price}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Quantity</span>
                          <p className={styles.empname}>{details.data.quantity}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Descriprion</span>
                          <p className={styles.empname}>{details.data.description}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Size</span>
                          <p className={styles.empname}>{details.data.size}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="col-lg-6 sm-6">
                  <div className={styles.profilepicdiv}>
                    {details?.data?.images?.map((file) => (
                      <img src={imageUrl + file} alt="Main logo" className={styles.profilepic} />
                    ))}
                  </div>
                </div>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
});

export default ViewVariant;
