import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_CATEGORIES = `${envValues.REACT_APP_API_ENDPOINT}/category?active=true&`;
export const REACT_APP_API_CATEGORY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/category/select`;
export const REACT_APP_API_SUBCATEGORY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/subcategory/select`;
export const REACT_APP_API_FETCH_SUBCATEGORIES = `${envValues.REACT_APP_API_ENDPOINT}/subcategory?active=true&`;
export const REACT_APP_API_FETCH_STOCKS = `${envValues.REACT_APP_API_ENDPOINT}/stock?`;
export const REACT_APP_API_FETCH_PRODUCT = `${envValues.REACT_APP_API_ENDPOINT}/product?`;
export const REACT_APP_API_FETCH_REFUND = `${envValues.REACT_APP_API_ENDPOINT}/refund/all-refund?`;
export const REACT_APP_API_FETCH_ORDERS = `${envValues.REACT_APP_API_ENDPOINT}/order/all-orders?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;
// export const REACT_APP_API_FETCH_ORDERCOUNT = `${envValues.REACT_APP_API_ENDPOINT}/ordercount?`;
export const REACT_APP_API_FETCH_REFUNDCOUNT = `${envValues.REACT_APP_API_ENDPOINT}/dashboard/refundcount?`;
export const REACT_APP_API_FETCH_STOCKCOUNT = `${envValues.REACT_APP_API_ENDPOINT}/dashboard/stockcount?`;
