/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import TableViewPopup from '../TableViewPopup/TableViewPopup';
import styles from './ActionButtons.module.scss';
import useToggle from '../../hooks/useToggle';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import { getCookies } from '../../hooks/useCookies';

function ActionButtons({ data, section, viewPopUp, deleteCondition, setTableData }) {
  const { actionbtnStyle } = useContext(TableContext);
  const [actionPermissions, setActionPermission] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setDeletemessage] = useState();
  const deleteMessage = `Are you sure, you want to delete ${data.name} ?`;
  const [canDelete, setCanDelete] = useState(true);
  const { setDeletedData } = useContext(TableContext);
  const [showTablePopUp, setShowTablePopUp] = useToggle();

  const actionView = (param) => () => {
    let encodeText;
    console.log(param, 'params');
    if (section === 'variant') {
      // eslint-disable-next-line no-underscore-dangle
      encodeText = param.variants._id;
    } else {
      encodeText = param.id;
    }
    // eslint-disable-next-line no-underscore-dangle
    const stockId = param.variants && param.variants.length > 0 ? param.variants[0]?._id : null;
    const isStockSection = section.toLowerCase() === 'stock';
    const idToUse = isStockSection ? stockId : encodeText;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      navigate(`/${section}/viewdetails/${idToUse}`);
    }
  };

  const actionEdit = (params) => {
    let encodeText;
    console.log(params, 'params');
    if (section === 'variant') {
      // eslint-disable-next-line no-underscore-dangle
      encodeText = params.variants._id;
    } else {
      encodeText = params.id;
    }
    // eslint-disable-next-line no-underscore-dangle
    const stockId = params.variants && params.variants.length > 0 ? params.variants[0]?._id : null;
    const isStockSection = section.toLowerCase() === 'stock';
    const idToUse = isStockSection ? stockId : encodeText;
    if (section === 'variant') {
      navigate(`/${section}/edit/${encodeText}`);
    } else {
      navigate(`/${section}/edit/${idToUse}`);
    }
  };

  const handleDelete = (params) => {
    confirmAlert({
      title: canDelete ? '' : 'Oops!!',
      message: deleteMessage,
      buttons: canDelete
        ? [
            {
              label: 'yes',
              onClick: async () => {
                try {
                  const actionData = {};
                  if (section === 'variant') {
                    // eslint-disable-next-line no-underscore-dangle
                    actionData.actionUrl = `/product/${section}/${params.variants._id}`;
                  } else {
                    actionData.actionUrl = `${section}/${params.id}`;
                  }
                  actionData.actionMethod = 'delete';
                  // eslint-disable-next-line no-underscore-dangle
                  const response = await dispatch(Entry(actionData));
                  console.log(response, 'vbnmm');
                  if (
                    response.payload.code === 200 ||
                    response.payload.code === 204 ||
                    response.payload.code === 201
                  ) {
                    toast.success('Row deleted successfully');
                    setDeletedData(`data last deleted is ${params.id}`);
                    setTableData((prevData) =>
                      prevData.filter((item) =>
                        section === 'variant'
                          ? // eslint-disable-next-line no-underscore-dangle
                            item.variants._id === params.variants._id
                          : item.id !== params.id
                      )
                    );
                  } else {
                    const errorMessage = response.payload?.message || 'Failed to delete the entry';
                    toast.error(errorMessage);
                  }
                } catch (error) {
                  const errorMessage =
                    error?.response?.data?.message || 'Failed to delete the entry';
                  toast.error(errorMessage);

                  confirmAlert({
                    title: 'Error',
                    message: errorMessage,
                    buttons: [
                      {
                        label: 'Ok',
                      },
                    ],
                  });
                }
              },
            },
            {
              label: 'No',
            },
          ]
        : [
            {
              label: 'Ok',
            },
          ],
    });
  };

  useEffect(() => {
    const temp = section.replace(/\s+/g, '').toLowerCase();
    const permissions = getCookies('USERPERMISSION');
    permissions.forEach((val) => {
      if (val.section.toLowerCase() === temp) {
        setActionPermission({
          view: val.view ? val.view : false,
          edit: val.edit ? val.edit : false,
          create: val.create ? val.create : false,
          delete: val.delete ? val.delete : false,
        });
      }
    });
    if (deleteCondition.checkCondition) {
      setCanDelete(false);
      setDeletemessage('Sorry you cant delete this data');
    }
  }, []);
  return (
    <>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
        {actionPermissions.view && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={actionView(data)}
          >
            {' '}
            <i className="fa fa-eye" />
          </button>
        )}
        {actionPermissions.edit && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={() => {
              actionEdit(data);
            }}
          >
            {' '}
            <i className="fa fa-edit" />
          </button>
        )}
        {actionPermissions.delete && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={() => {
              handleDelete(data);
            }}
          >
            {' '}
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
      {showTablePopUp ? (
        <TableViewPopup dataId={data.id} handleClose={setShowTablePopUp} section={section} />
      ) : null}
    </>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  viewPopUp: PropTypes.bool.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  setTableData: PropTypes.func.isRequired,
};
ActionButtons.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
};
export default ActionButtons;
