/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Container, Image, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { toast } from 'react-toastify';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';

const ViewIndex = React.memo(() => {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  // const [selectedOrderStatus, setSelectedOrderStatus] = useState({});
  // const { viewformStyle } = useContext(TableContext);
  const { orderId } = useParams();
  const imageUrl = 'http://localhost:4075/upload/product/';
  const token = `Bearer ${getCookies('Token')}`;
  const [details, setDetails] = useState({ loading: true, data: [] });
  let statusCode;

  const updateOrderStatus = async (newOrderStatus) => {
    const response = await axios.put(
      `${envValues.REACT_APP_API_ENDPOINT}/order/update-order-status/${orderId}`,
      {
        orderStatus: newOrderStatus,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Order status updated successfully:', response.data);
    if (response.data.code === 200) {
      toast.success('Order status updated successfully');
    } else if (
      response.data.code === 401 ||
      response.data.code === 400 ||
      response.data.code === 500
    ) {
      console.log('asdasdsda');
      toast.error(response.data.data.message);
    }
  };

  const getCategoryDetails = async () => {
    console.log(orderId, 'asvbna');
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/order/user-order/${orderId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        console.log(res, 'reass');
        if (statusCode === 200) {
          setDetails({ loading: false, data: res.data.data.orderDetails });
        } else {
          setDetails({ loading: false });
        }
      })
      .catch((err) => {
        setDetails({ loading: false });
        return err;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategoryDetails();
    // updateOrderStatus();
    setDashboardHeader('Order Details');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        {console.log(details, 'details')}

        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {details.loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#39979d',
              }}
            />
          ) : (
            <Container fluid>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={12}>
                        <div className={styles.tableContainer}>
                          <div className={styles.viewTable}>
                            <h4 className={styles.tableTitle}>User Details</h4>
                            <div className={styles.tableRow}>
                              <div className={styles.tableCell}>UserName</div>
                              <div className={styles.tableCell}>Email</div>
                            </div>
                            <div className={styles.tableRow}>
                              <div className={styles.tableCell}>{details.data.user.name}</div>
                              <div className={styles.tableCell}>{details.data.user.email}</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={12}>
                        <div className={styles.tableContainer}>
                          <div className={styles.viewTable}>
                            <h4 className={styles.tableTitle}>Product Details</h4>
                            <div className={styles.tableRow}>
                              <div className={styles.tableCell}>Brand</div>
                              <div className={styles.tableCell}>Name</div>
                              <div className={styles.tableCell}>Color</div>
                              <div className={styles.tableCell}>Size</div>
                              <div className={styles.tableCell}>Quantity</div>
                              <div className={styles.tableCell}>Price</div>
                              <div className={styles.tableCell}>Image</div>
                              <div className={styles.tableCell}>Total</div>
                            </div>
                            {details.data.products.map((product) => (
                              // eslint-disable-next-line no-underscore-dangle
                              <div className={styles.tableRow} key={product._id}>
                                <div className={styles.tableCell}>{product.productId.brand}</div>
                                <div className={styles.tableCell}>{product.productId.name}</div>
                                <div className={styles.tableCell}>{product.variantId.color}</div>
                                <div className={styles.tableCell}>{product.variantId.size}</div>
                                <div className={styles.tableCell}>{product.quantity}</div>
                                <div className={styles.tableCell}>{product.variantId.price}</div>
                                <div className={styles.tableCell}>
                                  <Image
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    src={imageUrl + product.variantId.images[0]}
                                    alt="Product"
                                    className={styles.productImage}
                                  />
                                </div>
                                <div className={styles.tableCell}>
                                  {product.quantity * product.variantId.price}
                                </div>
                              </div>
                            ))}
                            <div className={styles.tableRows}>
                              <div className={styles.tableCell} colSpan="6" />
                              <div className={styles.orderStatus}>
                                OrderStatus
                                <select
                                  value={details.data.orderStatus}
                                  onChange={(e) => {
                                    const newOrderStatus = e.target.value;
                                    console.log(newOrderStatus, 'statusfdgf');
                                    updateOrderStatus(newOrderStatus);

                                    setDetails({
                                      ...details,
                                      data: {
                                        ...details.data,
                                        orderStatus: newOrderStatus,
                                      },
                                    });
                                  }}
                                >
                                  <option value="pending">Pending</option>
                                  <option value="processing">Processing</option>
                                  <option value="shipped">Shipped</option>
                                  <option value="delivered">Delivered</option>
                                  {/* <option value="cancelled">Cancelled</option> */}
                                </select>
                              </div>
                              <div className={styles.total}>
                                <div className={styles.subtotal}>Subtotal</div>
                                <div className={styles.tableCell}>
                                  {details.data.products.reduce(
                                    (sum, product) =>
                                      sum + product.quantity * product.variantId.price,
                                    0
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.addrtitle}>Address Details</span>
                          <Table striped bordered hover className={styles.addressTable}>
                            <tbody>
                              {details.data.address.address.map((address) => (
                                <div>
                                  <tr key={address.id} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>Name:</strong>
                                    </td>
                                    <td>{address.name}</td>
                                  </tr>
                                  <tr key={`${address.id}-phone`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>Phone:</strong>
                                    </td>
                                    <td>{address.phone}</td>
                                  </tr>
                                  <tr key={`${address.id}-building`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>Building:</strong>
                                    </td>
                                    <td>{address.building}</td>
                                  </tr>
                                  <tr
                                    key={`${address.id}-postalCode`}
                                    className={styles.addressRow}
                                  >
                                    <td className={styles.fields}>
                                      <strong>PostalCode:</strong>
                                    </td>
                                    <td>{address.postalCode}</td>
                                  </tr>
                                  <tr key={`${address.id}-street`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>Street:</strong>
                                    </td>
                                    <td>{address.street}</td>
                                  </tr>{' '}
                                  <tr key={`${address.id}-city`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>City:</strong>
                                    </td>
                                    <td>{address.city}</td>
                                  </tr>{' '}
                                  <tr key={`${address.id}-landmark`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>Landmark:</strong>
                                    </td>
                                    <td>{address.landmark}</td>
                                  </tr>{' '}
                                  <tr key={`${address.id}-state`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>State:</strong>
                                    </td>
                                    <td>{address.state}</td>
                                  </tr>{' '}
                                  <tr key={`${address.id}-country`} className={styles.addressRow}>
                                    <td className={styles.fields}>
                                      <strong>Country:</strong>
                                    </td>
                                    <td>{address.country}</td>
                                  </tr>{' '}
                                </div>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.addrtitle}>Transaction Details</span>
                          <Table striped bordered hover className={styles.addressTable}>
                            <tbody>
                              {details.data.address.address.map((address) => (
                                <div>
                                  <tr key={address.id} className={styles.addressRow}>
                                    <td className={styles.field}>
                                      <strong>Mode of Payment:</strong>
                                    </td>
                                    <td>Stripe</td>
                                  </tr>
                                  <tr key={`${address.id}-phone`} className={styles.addressRow}>
                                    <td className={styles.field}>
                                      <strong>Payment Status:</strong>
                                    </td>
                                    <td>{details.data.paymentStatus}</td>
                                  </tr>
                                </div>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
});

export default ViewIndex;
