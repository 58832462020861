/* eslint-disable react/function-component-definition */
import { useParams } from 'react-router-dom';
/* eslint-disable react/function-component-definition */
import axios from 'axios';
import React, { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './index.module.scss';
import CustomTable from '../../components/CustomTable/CustomTable';
import { getCookies } from '../../hooks/useCookies';
import envValues from '../../enviornment';

const VariantIndex = React.memo(() => {
  const params = useParams();
  const { productId } = params;
  console.log(productId, 'dfcvxcid');
  const token = `Bearer ${getCookies('Token')}`;

  const url = `${envValues.REACT_APP_API_ENDPOINT}/product/${productId}/variant?productId=${productId}&`;
  console.log(url, 'url');
  const getPackages = async () => {
    axios.get(url, {
      method: 'GET',
      headers: { Authorization: token },
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getPackages();
  }, []);

  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={url}
        section="variant"
        toggleButton={{ show: true, field: 'active' }}
        deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
      />
    </div>
  );
});
export default VariantIndex;
