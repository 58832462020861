/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './index.module.scss';
import { REACT_APP_API_FETCH_REFUND } from '../../redux/apiConstants';
import CustomTable from '../../components/CustomTable/CustomTable';

const RefundIndex = React.memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={REACT_APP_API_FETCH_REFUND}
        section="refund"
        deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
      />
    </div>
  );
});
export default RefundIndex;
