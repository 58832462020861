/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Container, Image, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { toast } from 'react-toastify';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';

const RefundView = React.memo(() => {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  // const { viewformStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  // const [selectedOrderStatus, setSelectedOrderStatus] = useState({});
  const [selectedRefundStatus, setSelectedRefundStatus] = useState('');
  // const { viewformStyle } = useContext(TableContext);
  const { refundId } = useParams();
  const imageUrl = 'http://localhost:4075/upload/product/';
  const token = `Bearer ${getCookies('Token')}`;
  const [details, setDetails] = useState({ loading: true, data: [] });
  let statusCode;

  const handleRefundStatusChange = async () => {
    try {
      let apiEndpoint;
      let actionVerb;
      console.log(selectedRefundStatus, 'fshkjdfsu');
      if (selectedRefundStatus === 'cancel') {
        apiEndpoint = `${envValues.REACT_APP_API_ENDPOINT}/order/cancelRefund/${refundId}`;
        actionVerb = 'cancelled';
      } else if (selectedRefundStatus === 'approve') {
        apiEndpoint = `${envValues.REACT_APP_API_ENDPOINT}/order/approveRefund/${refundId}`;
        actionVerb = 'approved';
      }

      const response = await axios.put(
        apiEndpoint,
        { refundStatus: selectedRefundStatus },
        { headers: { Authorization: token } }
      );

      if (response.data.code === 200) {
        console.log(`Refund ${actionVerb} successfully`);
        toast.success('Order status updated successfully');

        setDetails({
          ...details,
          data: {
            ...details.data,
            refundStatus: selectedRefundStatus,
          },
        });
      } else if (
        response.data.code === 401 ||
        response.data.code === 400 ||
        response.data.code === 500
      ) {
        console.log('asdasdsda');
        toast.error(response.data.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRefundDetails = async () => {
    console.log(refundId, 'asvbna');
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/refund/getRefund/${refundId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        console.log(res, 'reass');
        if (statusCode === 200) {
          setDetails({ loading: false, data: res.data.data.orderDetails });
        } else {
          setDetails({ loading: false });
        }
      })
      .catch((err) => {
        setDetails({ loading: false });
        return err;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getRefundDetails();
    // updateOrderStatus();
    setDashboardHeader('Refund Details');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        {console.log(details, 'details')}

        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {details.loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#39979d',
              }}
            />
          ) : (
            <Container fluid>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={12}>
                        <div className={styles.tableContainer}>
                          <div className={styles.viewTable}>
                            <h4 className={styles.tableTitle}>Product Details</h4>
                            <div className={styles.tableRow}>
                              <div className={styles.tableCell}>Brand</div>
                              <div className={styles.tableCell}>Name</div>
                              <div className={styles.tableCell}>Color</div>
                              <div className={styles.tableCell}>Size</div>
                              <div className={styles.tableCell}>Price</div>
                              <div className={styles.tableCell}>Image</div>
                            </div>
                            {details.data.products.map((product) => (
                              // eslint-disable-next-line no-underscore-dangle
                              <div className={styles.tableRow} key={product._id}>
                                <div className={styles.tableCell}>{product.brand}</div>
                                <div className={styles.tableCell}>{product.name}</div>
                                <div className={styles.tableCell}>
                                  {details.data.variantId.color}
                                </div>
                                <div className={styles.tableCell}>
                                  {details.data.variantId.size}
                                </div>
                                <div className={styles.tableCell}>
                                  {details.data.variantId.price}
                                </div>
                                <div className={styles.tableCell}>
                                  <Image
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    src={imageUrl + details.data.variantId.images[0]}
                                    alt="Product"
                                    className={styles.productImage}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={styles.refundReasonSection}>
                          <div className={styles.refundReason}>
                            {details.data.refundReason
                              ? `Refund Reason: ${details.data.refundReason}`
                              : 'No refund reason provided'}
                          </div>
                        </div>
                        <div className={styles.orderStatus}>
                          Refund Status
                          <Form.Control
                            as="select"
                            value={selectedRefundStatus}
                            onChange={(e) => setSelectedRefundStatus(e.target.value)}
                            onBlur={handleRefundStatusChange}
                            disabled={
                              details.data.refundStatus === 'approved' ||
                              details.data.refundStatus === 'cancelled'
                            }
                          >
                            <option value="" disabled>
                              {details.data.refundStatus || 'Select Refund Status'}
                            </option>
                            <option value="approve">Approve</option>
                            <option value="cancel">Cancel</option>
                          </Form.Control>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
});

export default RefundView;
